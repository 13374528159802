window.loadScript = function(url, callback ) {
  var script = document.createElement( "script" )
  script.type = "text/javascript";
  if(script.readyState) {
    script.onreadystatechange = function() {
      if ( script.readyState === "loaded" || script.readyState === "complete" ) {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = function() {
      callback();
    };
  }

  script.src = url;
  document.getElementsByTagName( "head" )[0].appendChild( script );
}

window.turboStreamRequest = (url, method = 'GET', body = null) => {
  var headers = {
    'Content-Type': 'application/json',
    'Accept': 'text/vnd.turbo-stream.html'
  }
  if(window.sessionToken) {
    headers['Authorization'] = `Bearer ${window.sessionToken}`;
  }
  return fetch(url, {
    method: method,
    headers: headers,
    body: method === 'GET' ? null : JSON.stringify(body)
  })
  .then(response => response.text())
  .then(turboStreamResponse => Turbo.renderStreamMessage(turboStreamResponse))
  .catch(error => {
    console.error('Turbo Stream Request failed', error);
  });
};